// Generated by Framer (19eed98)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jiZPqRcnn"];

const serializationHash = "framer-KA5R8"

const variantClassNames = {jiZPqRcnn: "framer-v-880ar1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, lKrthxkJw: image ?? props.lKrthxkJw ?? {src: "https://framerusercontent.com/images/MNXUWnqPwzFG0jOTr8sKJm9Gc.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/MNXUWnqPwzFG0jOTr8sKJm9Gc.png?scale-down-to=512 512w, https://framerusercontent.com/images/MNXUWnqPwzFG0jOTr8sKJm9Gc.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/MNXUWnqPwzFG0jOTr8sKJm9Gc.png?scale-down-to=2048 2048w, https://framerusercontent.com/images/MNXUWnqPwzFG0jOTr8sKJm9Gc.png 2940w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, lKrthxkJw, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jiZPqRcnn", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KA5R8", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fit", intrinsicHeight: 1616, intrinsicWidth: 2940, pixelHeight: 1616, pixelWidth: 2940, sizes: "min(600px, 100vw)", ...toResponsiveImage(lKrthxkJw)}} className={cx("framer-880ar1", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jiZPqRcnn"} ref={ref ?? ref1} style={{...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KA5R8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KA5R8 .framer-275tk8 { display: block; }", ".framer-KA5R8 .framer-880ar1 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 20px; height: 600px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 600px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KA5R8 .framer-880ar1 { gap: 0px; } .framer-KA5R8 .framer-880ar1 > * { margin: 0px; margin-bottom: calc(20px / 2); margin-top: calc(20px / 2); } .framer-KA5R8 .framer-880ar1 > :first-child { margin-top: 0px; } .framer-KA5R8 .framer-880ar1 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 600
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"lKrthxkJw":"image"}
 * @framerImmutableVariables false
 */
const FramerM2MpdT0Lo: React.ComponentType<Props> = withCSS(Component, css, "framer-KA5R8") as typeof Component;
export default FramerM2MpdT0Lo;

FramerM2MpdT0Lo.displayName = "Image";

FramerM2MpdT0Lo.defaultProps = {height: 600, width: 600};

addPropertyControls(FramerM2MpdT0Lo, {lKrthxkJw: {__defaultAssetReference: "data:framer/asset-reference,MNXUWnqPwzFG0jOTr8sKJm9Gc.png?originalFilename=Screenshot+2023-10-04+at+2.53.09%C3%A2%C2%80%C2%AFPM.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerM2MpdT0Lo, [])